import { SC } from "@bigspring/core-components";
import { motion } from "framer-motion";

export function GlobalStyle() {
  return (
    <SC.Global
      styles={SC.css`
    body {
      margin: 0;
    }
    
    a {
      text-decoration:none;
    }
  `}
    />
  );
}

export const Wrapper = SC.styled(motion.div)`
  display: flex;
  min-height: 100vh;
  min-height: 100dvh;
`;

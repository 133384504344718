import { TITLE_SUFFIX } from "config/routes";
import Head from "next/head";
import { PropsWithChildren } from "react";
import { GlobalStyle, Wrapper } from "./styles";

export default function LoginLayout({ children }: PropsWithChildren<unknown>) {
  return (
    <>
      <GlobalStyle />
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta charSet="utf-8" />

        <title>{TITLE_SUFFIX}</title>
        <meta name="description" content="BigSpring Admin" />
      </Head>
      <Wrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </Wrapper>
    </>
  );
}

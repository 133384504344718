import Head from "next/head";
import React, { useEffect, ReactElement } from "react";
import { Login } from "./../../components/scenes/Login";
import { TITLE_SUFFIX } from "config/routes";
import { useAnalytics } from "components/shared/Analytics";
import LoginLayout from "@shared/LoginLayout";

export default function LoginPage() {
  const { adminTrack } = useAnalytics();

  useEffect(() => {
    adminTrack?.pageLoaded("Login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <title>Login | {TITLE_SUFFIX}</title>
      </Head>
      <Login />
    </>
  );
}

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <LoginLayout>{page}</LoginLayout>;
};

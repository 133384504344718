import { CC, CCTypes } from "@bigspring/core-components";
import { useAnalytics } from "components/shared/Analytics";
import { useAuth } from "components/shared/Authentication";
import { useState } from "react";
import {
  AuthenticationSource,
  useAuthInitMutation,
  useAuthVerifyMutation,
  useGoogleAuthVerifyMutation,
} from "@gql/generated/graphql";

export function Login() {
  const { login } = useAuth();
  const { client } = useAnalytics();

  const [authInit] = useAuthInitMutation();
  const [authVerify] = useAuthVerifyMutation();
  const [googleAuthVerifyFn] = useGoogleAuthVerifyMutation();

  const [authInitId, setAuthInitId] = useState("");

  const onSendVerificationCode = async (profileIdentifier: string) => {
    try {
      const results = await authInit({
        variables: {
          profileIdentifier,
        },
      });

      if (!results?.data?.authInit) {
        throw new Error("Unable to init authentication");
      }

      setAuthInitId(results.data.authInit);
    } catch (e) {
      const { code, message } = (e || {}) as any;
      console.info({ e });
      throw { code, message };
    }
  };

  const onSubmitVerificationCode = async (verificationCode: string) => {
    try {
      await authVerify({
        variables: {
          authInitId,
          code: verificationCode,
        },
      });

      await login();
    } catch (e) {
      const { code, message } = (e || {}) as any;
      console.info({ e });
      throw { code, message };
    }
  };

  const onGAuthSuccess = async (credential: string) => {
    try {
      await googleAuthVerifyFn({
        variables: {
          idToken: credential,
          source: AuthenticationSource.Admin,
        },
      });

      await login();
    } catch (e) {
      const { code, message } = (e || {}) as any;
      console.error({ e });
      throw { code, message };
    }
  };

  return (
    <CC.Login
      googleApiKey={String(process.env.NEXT_PUBLIC_GOOGLE_API_KEY)}
      googleMapsApiKey={String(process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY)}
      onSendVerificationCode={onSendVerificationCode}
      onSubmitVerificationCode={onSubmitVerificationCode}
      segment={client as CCTypes.SegmentPropFunctions}
      source="ADMIN"
      isSsoEnabled={false}
      onGoogleAuthSuccess={onGAuthSuccess}
    />
  );
}
